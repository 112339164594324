import React from "react";
import "./App.css";
import Tiptap from "./Tiptap";

function App() {
  return (
    <div className="App">
      <h2>Ideaflow Coding Challenge — kumareth</h2>
      <p>
        Use markdown to edit the text below, <code>#</code>, <code>##</code>, <code>###</code>, <code>**</code>, <code>```</code>, etc.
      </p>

      <Tiptap />
    </div>
  );
}

export default App;
