import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'

import List from '.'

function suggestioner(suggestions, type) {
  return {
    items: ({ query }) => {
      const filteredSuggestions = suggestions
        .map(item => item.toLowerCase())
        .filter(item => item.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 5)

      // if the suggestion isn't found, return the original query, else return the suggestion.
      const returnSuggestions = [
        // if the query is not in the filtered suggestions, return the query
        ...(query.trim().length && !filteredSuggestions.includes(query) ? [query.trim()] : []),

        // always append the filtered suggestions
        ...filteredSuggestions,
      ]

      return returnSuggestions
    },

    render: () => {
      let component
      let popup

      return {
        onStart: props => {
          component = new ReactRenderer(List, {
            props: {
              ...props,
              type,
            },
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props) {
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()

            return true
          }

          return component.ref?.onKeyDown(props)
        },

        onExit() {
          popup[0].destroy()
          component.destroy()
        },
      }
    },
  }
}

export default suggestioner