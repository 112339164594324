import React from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import { Mention } from './components/extension-mention'
import { Hashtag } from './components/extension-hashtag'
import { Angles } from './components/extension-anglebrackets'

import suggestioner from './components/List/suggestioner'
import { anglesSuggestions, defaultContentJSON, hashtagSuggestions, mentionSuggestions } from './components/List/suggestions'

const Tiptap = () => {
  const editor = useEditor({
    extensions: [
      StarterKit,

      // Mentions `@` feature
      Mention.configure({
        HTMLAttributes: {
          class: 'hashtag',
          style: `cursor: pointer; user-select: none; color: #7CCCD9;`,
        },
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
        },
        suggestion: suggestioner(mentionSuggestions, 'mention'),
      }),

      // Hashtags `#` feature
      Hashtag.configure({
        HTMLAttributes: {
          class: 'hashtag',
          style: `cursor: pointer; user-select: none; color: #7CCCD9;`,
        },
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
        },
        suggestion: suggestioner(hashtagSuggestions, 'hashtag'),
      }),

      // Anglebrackets `<>` feature
      // Using `<>match_string` for prefixing it with a query seems weird, but seems like that's what the coding challenge is asking for.
      // I'd personally prefer `<match_string>` behavior, though I haven't implemented that here.
      Angles.configure({
        HTMLAttributes: {
          class: 'hashtag',
          style: `cursor: pointer; user-select: none; color: #7CCCD9;`,
        },
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
        },
        suggestion: suggestioner(anglesSuggestions, 'angles'),
      }),
    ],

    content: defaultContentJSON,

    onUpdate: ({ editor }) => {
      console.log(JSON.stringify(editor.getJSON()))
    },
  })

  return (
    <EditorContent
      editor={editor}
      className="ProseMirror-container"
    />
  )
}

export default Tiptap