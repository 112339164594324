// Open Source Code: https://github.com/ueberdosis/tiptap/tree/main/packages/extension-mention
// I used the above source code as a reference to create the extension-hashtag extension.

import { mergeAttributes, Node } from '@tiptap/core';
import Suggestion, { SuggestionOptions } from '@tiptap/suggestion';
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { PluginKey } from 'prosemirror-state';

export type HashtagOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: HashtagOptions;
    node: ProseMirrorNode;
  }) => string;
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

export const HashtagPluginKey = new PluginKey('hashtag');

export const Hashtag = Node.create<HashtagOptions>({
  name: 'hashtag',

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
      },
      suggestion: {
        char: '#',
        pluginKey: HashtagPluginKey,

        // runs only when a selection is made
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const { nodeAfter } = editor.view.state.selection.$to;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();

          window.getSelection()?.collapseToEnd();
        },
        allow: ({ state, range, editor }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
      },
    };
  },

  group: 'inline',

  inline: true,

  selectable: true,

  atom: true,

  lastNode: null,
  onUpdate() {
    console.log('onUpdate')
    const editor = this.editor
    
    const { state, dispatch } = editor.view;
    const { selection } = state;
    const { empty, anchor } = selection;

    // get last character
    const lastChar = state.doc.textBetween(anchor - 1, anchor, null, '\ufffc');

    let lastNode: any;
    // when space or enter is pressed
    if (lastChar === ' ' || lastChar.length === 0) {
      // insert lastNode in the editor as a span
      // @ts-ignore
      if (this.lastNode) {
        const tr = state.tr.insertText(
          this.options.suggestion.char || '',
          anchor - 1,
          anchor
        );

        dispatch(tr);
      }
    } else {
      // get last node in the editor and check if it is of type "hashtag", if yes, save it in this.lastNode
      lastNode = state.doc.nodeAt(anchor - 1);

      console.log({
        hashTagNode: lastNode,
      })
    }
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes._id) {
            return {};
          }

          return {
            'data-id': attributes?._id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }
          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        { 'data-id': node?.attrs?.id?._id ? node?.attrs?.id?._id : '' },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText(
                this.options.suggestion.char || '',
                pos,
                pos + node.nodeSize
              );

              return false;
            }
          });

          return isMention;
        }),
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
